<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";

export default {
  locales: {
    pt: {
      'Courses': 'Cursos',
      'Buy': 'Comprar',
    },
    es: {
      'Courses': 'Cursos',
      'Buy': 'Comprar',
    }
  },
  components: {
    Layout,
  },
  data() {
    return {
      courses: null,
    };
  },
  methods: {
    getCourses() {
      this.courses = null;

      api
        .get('courses')
        .then(response => {
          if (response.data.status == 'success') {
            this.courses = response.data.list
          } else {
            this.courses = []
          }
        })
        .catch(error => {
          if (error) {
            this.courses = []
          }
        })
    }
  },
  mounted() {
    this.getCourses()
  }
};
</script>

<template>
  <Layout>
    <div class="page-title-box d-md-flex justify-content-between">
      <div>
        <h3>{{ t('Courses') }}</h3>
        <p></p>
      </div>
    </div>

    <div v-if="!courses" class="card">
      <div class="card-body min-vh-25 d-flex justify-content-center align-items-center">
        <b-spinner variant="default" role="status"></b-spinner>
      </div>
    </div>

    <div v-else-if="courses && courses.length == 0" class="card">
      <div class="card-body min-vh-25 d-flex justify-content-center align-items-center">
        <div class="text-center">
          <i class="far fa-sad-cry font-size-24"></i><br>
          ainda não temos nenhum curso disponível
        </div>
      </div>
    </div>

    <div v-else>
      <div class="row">
        <div class="col-md-4" v-for="(row, index) in courses" :key="index">
          <router-link tag="a" :to="'/courses/' + row.slug">
            <div class="card">
              <div class="card-body p-2">
                <img v-if="row.cover" class="course-cover" :src="row.cover" />
                <div class="p-2">
                  <h5>{{ row.title }}</h5>
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.course-cover {
  border-radius: 5px;
  margin-bottom: 10px;
  width: 100%;
  object-fit: cover;
  aspect-ratio: 10 / 5;
}
</style>